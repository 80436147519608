<script setup>
import { router, usePage } from '@inertiajs/vue3';
import FormSection from '@/Components/FormSection.vue';
import { computed, ref, watch } from 'vue';
import TicketSummaryTable from '@/Pages/Ticket/Partials/TicketSummaryTable.vue';
import { collect } from 'collect.js';
import TicketContentTimelineItem from '@/Pages/Ticket/Partials/TicketContentTimelineItem.vue';
import {
    mdiArrowLeft,
    mdiClose,
    mdiFileEdit,
    mdiHandCoinOutline,
    mdiPlus,
} from '@mdi/js';
import TicketContentModal from '@/Pages/Ticket/Partials/TicketContentModal.vue';
import LinkVuetify from '@/Components/LinkVuetify.vue';
import ClaimModal from '@/Pages/Ticket/Partials/ClaimModal.vue';
import SpecialOrderModal from '@/Pages/Ticket/Partials/SpecialOrderModal.vue';
import ConfirmationPromiseModal from '@/Components/ConfirmationPromiseModal.vue';

const page = usePage();
const edit = computed(() => {
    return !route().current('ticket.show');
});

window.onpopstate = (e) => {
    console.log(e.state);
};

const ticket = computed(() => {
    return page.props.ticket;
});
const responsibles = computed(() => {
    return collect(page.props.lists.responsibles)
        .mapWithKeys((responsible) => [responsible.value, responsible.name])
        .all();
});
const createdEvent = computed(() => ticket.value.content[0]);
const foldedLines = ref([]);
watch(
    () => ticket.value.content,
    () => {
        const lines = ticket.value.content.slice(1);
        foldedLines.value = [];
        lines.forEach((line) => {
            if (line.subtype?.name === 'ticket-meta') return;

            const lastLine = foldedLines.value[foldedLines.value.length - 1];
            if (
                lastLine &&
                lastLine.note === 'updated' &&
                lastLine.subtype_id === null &&
                line.note === 'updated' &&
                line.subtype_id === null
            ) {
                foldedLines.value[foldedLines.value.length - 1] = {
                    folded: true,
                    foldedIndex: foldedLines.value.length - 1,
                    ...line,
                    content: [...lastLine.content, line],
                };
            } else {
                foldedLines.value.push({
                    ...line,
                    content: [line],
                });
            }
        });
    },
    {
        immediate: true,
    },
);

const contentLines = computed(() => {
    const contentLines = [];
    foldedLines.value
        .filter((line) => line.subtype?.name !== 'ticket-meta')
        .forEach((line, index) => {
            if (line.folded === false) {
                const lines = [...line.content];
                lines[0].folded = false;
                lines[0].foldedIndex = index;
                contentLines.push(...line.content);
            } else {
                contentLines.push(line);
            }
        });
    return contentLines;
});
const meta = computed(() => {
    return (
        ticket.value.content.find((line) => {
            return line.subtype?.name === 'ticket-meta';
        }) ?? null
    );
});

const formatDate = (date) => {
    return moment(date).format('DD.MM.YYYY HH:mm');
};

const fold = (index) => {
    foldedLines.value[index].folded = true;
};
const unfold = (index) => {
    foldedLines.value[index].folded = false;
};
// New content
const showAddContentModal = ref(false);
const showClaimModal = ref(false);

const back = () => {
    router.visit(route('ticket.index'));
};

const specialOrder = ref(page.props.ticket.special_order);
const confirmationModal = ref(null);
const showSpecialOrderModal = ref(false);
const confirmCreateSpecialOrder = async () => {
    if (specialOrder.value !== null) {
        showSpecialOrderModal.value = true;
        return;
    }
    showSpecialOrderModal.value = await confirmationModal.value.confirm(
        'Wollen Sie eine SAN für dieses Ticket erstellen?',
        'Ticket #' + ticket.value.id,
    );
};
</script>

<template>
    <v-container>
        <ConfirmationPromiseModal
            ref="confirmationModal"
        ></ConfirmationPromiseModal>
        <TicketContentModal
            v-model="showAddContentModal"
            :ticket
        ></TicketContentModal>
        <SpecialOrderModal
            :show="showSpecialOrderModal"
            :ticket
            :edit
            @close="showSpecialOrderModal = false"
            @updated="specialOrder = $event"
        ></SpecialOrderModal>
        <ClaimModal v-model="showClaimModal" :ticket :edit></ClaimModal>
        <FormSection full-width>
            <template #title>{{
                edit
                    ? $t('Edit :name', { name: 'Ticket' })
                    : 'Ticket #' + ticket.id
            }}</template>
            <template #description>
                <span v-if="!edit">{{
                    $t('ticket.information_description', {
                        id: ticket.id,
                    })
                }}</span>
                <div v-else>
                    <p
                        class="tw-mb-2"
                        v-html="
                            $t('ticket.edit_description_1', { id: ticket.id })
                        "
                    ></p>
                    <p v-html="$t('ticket.edit_description_2')"></p>
                </div>
            </template>
            <template #form>
                <v-row class="tw-flex tw-items-center tw-px-2 tw-pt-2">
                    <v-btn
                        color="info"
                        variant="plain"
                        :icon="mdiArrowLeft"
                        @click="back()"
                    ></v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        :icon="mdiHandCoinOutline"
                        color="primary"
                        variant="plain"
                        @click="showClaimModal = true"
                    ></v-btn>
                    <LinkVuetify
                        v-if="
                            !edit &&
                            page.props.ticket.permissions.canUpdateTicket
                        "
                        :icon="mdiFileEdit"
                        color="secondary"
                        variant="plain"
                        :href="route('ticket.edit', { ticket: ticket.id })"
                    ></LinkVuetify>
                    <LinkVuetify
                        v-else
                        :icon="mdiClose"
                        variant="plain"
                        color="danger"
                        :href="route('ticket.show', { ticket: ticket.id })"
                        >Show</LinkVuetify
                    >
                </v-row>
                <v-row>
                    <v-col>
                        <TicketSummaryTable
                            :meta
                            :ticket
                            :special-order="specialOrder"
                            :responsibles
                            :edit
                            :lists="page.props.lists"
                            @create:special-order="confirmCreateSpecialOrder"
                        ></TicketSummaryTable>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-timeline
                            transition="v-slide-y-transition"
                            side="end"
                            :truncate-line="edit ? 'start' : 'both'"
                            class="tw-w-full"
                        >
                            <v-timeline-item
                                dot-color="secondary"
                                size="x-small"
                                width="90%"
                            >
                                <div class="tw-flex tw-items-center">
                                    <div class="tw-mr-8 tw-whitespace-nowrap">
                                        <span class="tw-block">{{
                                            formatDate(createdEvent.created_at)
                                        }}</span>
                                        <span class="tw-block tw-text-xs">
                                            {{ createdEvent.user.username }}
                                        </span>
                                    </div>
                                    <v-card>
                                        <v-card-text>
                                            <span class="tw-font-black"
                                                >{{
                                                    createdEvent.user.first_name
                                                }}
                                                {{
                                                    createdEvent.user.last_name
                                                }}</span
                                            >
                                            hat das Ticket
                                            <span class="tw-font-black"
                                                >#{{ ticket.id }}</span
                                            >
                                            erstellt.
                                        </v-card-text>
                                    </v-card>
                                </div>
                            </v-timeline-item>

                            <TicketContentTimelineItem
                                v-for="line in contentLines"
                                :key="`${line.id}-line`"
                                :ticket="ticket"
                                :content="line"
                                :edit="edit"
                                @unfold="unfold"
                                @fold="fold"
                            ></TicketContentTimelineItem>

                            <v-timeline-item
                                v-if="edit"
                                size="x-large"
                                fill-dot
                                dot-color="surface"
                                width="90%"
                            >
                                <template #icon>
                                    <v-btn
                                        color="primary"
                                        icon
                                        @click="showAddContentModal = true"
                                    >
                                        <v-icon :icon="mdiPlus" size="large">
                                        </v-icon>
                                    </v-btn>
                                </template>
                                <v-card>
                                    <v-card-text>
                                        <v-icon :icon="mdiArrowLeft"></v-icon>
                                        neuer Eintrag
                                    </v-card-text>
                                </v-card>
                            </v-timeline-item>
                        </v-timeline>
                    </v-col>
                </v-row>
            </template>
        </FormSection>
    </v-container>
</template>

<style scoped></style>
