<script setup></script>

<template>
    <v-card class="tw-rounded-xl tw-shadow-lg" variant="elevated">
        <v-toolbar color="secondary" density="comfortable">
            <v-toolbar-title class="tw-font-light tw-uppercase">
                <slot v-if="$slots.title" name="title"></slot>
            </v-toolbar-title>
            <slot name="toolbar"> </slot>
        </v-toolbar>

        <div class="tw-p-4">
            <slot></slot>
        </div>
    </v-card>
</template>
