<script setup></script>

<template>
    <div
        class="tw-relative tw-rounded-lg tw-border tw-border-solid tw-border-lime-300 tw-p-2"
    >
        <span
            v-if="$slots.title"
            class="tw-absolute tw-left-2 tw-top-0 tw-z-10 -tw-translate-y-1/2 tw-bg-surface tw-px-1 tw-text-xs tw-text-lime-600"
            ><slot name="title"></slot
        ></span>
        <slot></slot>
    </div>
</template>

<style scoped></style>
