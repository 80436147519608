<script setup>
import { computed, ref } from 'vue';
import colors from 'tailwindcss/colors';

const input = ref(null);

const props = defineProps({
    modelValue: { type: [String, Number, null], required: true },
    label: { type: String, default: null },
    color: {
        type: String,
        default: 'primary',
    },
    bgColor: {
        type: String,
        default: colors.gray['100'],
    },
    type: {
        type: String,
        default: 'text',
    },
    autofocus: {
        type: Boolean,
        default: false,
    },
    autocomplete: {
        type: String,
        default: null,
    },
    errorMessages: {
        type: [Array, String],
        default: () => [],
    },
    readonly: {
        type: Boolean,
        default: false,
    },
});

const value = computed({
    get: () => props.modelValue,
    set: (val) => emit('update:modelValue', val),
});

const emit = defineEmits(['update:modelValue']);
defineExpose({ focus: () => input.value.focus() });
</script>

<template>
    <v-text-field
        ref="input"
        v-model="value"
        :readonly
        class="tw-mb-2"
        :bg-color="bgColor"
        :error-messages="errorMessages"
        :color="color"
        :label="label"
        :type="type"
        :autofocus="autofocus"
        :autocomplete="autocomplete"
    >
        <template v-if="$slots['label']" #label>
            <slot name="label"></slot>
        </template>
        <template v-if="$slots['prepend-inner']" #prepend-inner>
            <slot name="prepend-inner"></slot>
        </template>
        <template v-if="$slots['prepend']" #prepend>
            <slot name="prepend"></slot>
        </template>
        <template v-if="$slots['append-inner']" #append-inner>
            <slot name="append-inner"></slot>
        </template>
        <template v-if="$slots['append']" #append>
            <slot name="append"></slot>
        </template>
    </v-text-field>
</template>
