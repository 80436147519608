<script setup>
import DashboardCard from '@/Pages/Dashboard/Widgets/DashboardCard.vue';
import { useAxios } from '@vueuse/integrations/useAxios';
import { computed, onUnmounted, ref } from 'vue';
import { useLocalStorage } from '@vueuse/core';
import StatusList from '@/Pages/Dashboard/Widgets/StatusList.vue';
import TitleOutline from '@/Components/TitleOutline.vue';
import { collect } from 'collect.js';
import { mdiTable } from '@mdi/js';
import UserStatusTable from '@/Pages/Dashboard/Widgets/UserStatusTable.vue';

const { data, execute } = useAxios(route('api.dashboard.online-status'));

const interval = setInterval(() => {
    execute();
}, 30000);

onUnmounted(() => {
    clearInterval(interval);
});

const params = useLocalStorage('online-status-filter', {
    filter: 'all',
    departments: false,
});
const filterItems = [
    { title: 'Anwesend', value: 'present' },
    { title: 'Abwesend', value: 'absent' },
    { title: 'Alle', value: 'all' },
];

const statusData = computed(() => {
    const transformedData = data.value?.map((status) => {
        const names = status.name.split(' ');

        return {
            ...status,
            firstName: names[0],
            lastName: names[1],
            present: ['Robert Stadler', 'Rene Thoma'].includes(status.name)
                ? true
                : status.present,
        };
    });

    if (params.value?.filter === 'all') {
        return transformedData;
    }

    return transformedData?.filter((status) => {
        return status.present === (params.value?.filter === 'present');
    });
});

const departmentData = computed(() => {
    if (!statusData.value) {
        return {};
    }

    return collect(statusData.value).groupBy('department').all();
});

const showUserStatusTable = ref(false);
</script>

<template>
    <UserStatusTable v-model="showUserStatusTable"></UserStatusTable>
    <DashboardCard>
        <template #title> Anwesenheit </template>
        <template #toolbar>
            <v-spacer></v-spacer>
            <v-toolbar-items>
                <v-btn
                    :disabled="!statusData"
                    @click="showUserStatusTable = true"
                >
                    <v-icon :icon="mdiTable"></v-icon>
                </v-btn>
            </v-toolbar-items>
            <v-select
                v-model="params.filter"
                class="tw-mr-2"
                :items="filterItems"
                hide-details
            >
            </v-select>
            <v-checkbox
                v-model="params.departments"
                class="tw-mr-2"
                label="Abt."
                hide-details
            ></v-checkbox>
        </template>
        <div
            v-if="!params.departments"
            class="tw-flex tw-h-96 tw-w-full tw-flex-col tw-flex-wrap tw-content-start"
        >
            <StatusList v-if="statusData" :data="statusData"></StatusList>
        </div>
        <TitleOutline
            v-for="(dData, department) in departmentData"
            v-else
            :key="department"
            class="tw-mb-4 tw-flex tw-max-h-64 tw-w-full tw-flex-col tw-flex-wrap"
        >
            <template #title>{{ department }}</template>
            <StatusList :data="dData"></StatusList>
        </TitleOutline>
    </DashboardCard>
</template>

<style scoped></style>
