<script setup>
import DialogModal from '@/Components/DialogModal.vue';
import { AgGridVue } from 'ag-grid-vue3';
import { ref, watch } from 'vue';
import { useAxios } from '@vueuse/integrations/useAxios';
import { mdiClose } from '@mdi/js';

const show = defineModel({
    type: Boolean,
    default: false,
});

const { data, isFinished, execute } = useAxios(
    route('api.dashboard.user-status'),
);
const rowData = ref([]);
watch(isFinished, (value) => {
    if (value) {
        rowData.value = data.value;
    }
});
watch(show, (value) => {
    if (value) {
        execute();
    }
});

const cellRendererParams = {
    cellEditor: 'agSelectCellEditor',
    cellEditorParams: {
        values: ['TA', 'PO', 'BR'],
    },
    editable: true,
};
const columnDefs = [
    { field: 'name', headerName: 'Name', width: 200 },
    {
        field: 'monday',
        headerName: 'Montag',
        width: 120,
        ...cellRendererParams,
    },
    {
        field: 'tuesday',
        headerName: 'Dienstag',
        width: 120,
        ...cellRendererParams,
    },
    {
        field: 'wednesday',
        headerName: 'Mittwoch',
        width: 120,
        ...cellRendererParams,
    },
    {
        field: 'thursday',
        headerName: 'Donnerstag',
        width: 120,
        ...cellRendererParams,
    },
    {
        field: 'friday',
        headerName: 'Freitag',
        width: 120,
        ...cellRendererParams,
    },
];

const onCellValueChanged = async (params) => {
    await axios.put(
        route('api.dashboard.user-status.update', {
            smarttime: params.data.name,
        }),
        params.data,
    );
};
</script>

<template>
    <DialogModal :show @close="show = false">
        <template #title
            ><div class="tw-flex tw-w-full tw-justify-between">
                Anwesenheit<v-icon
                    :icon="mdiClose"
                    @click="show = false"
                ></v-icon></div
        ></template>
        <template #content>
            <div class="tw-h-[700px]">
                <ag-grid-vue
                    v-if="isFinished"
                    style="height: 100%"
                    class="ag-theme-material ag-theme-material-dense"
                    :column-defs
                    :row-data
                    :single-click-edit="true"
                    :stop-editing-when-cells-lose-focus="true"
                    @cell-value-changed="onCellValueChanged"
                ></ag-grid-vue>
                <div
                    v-else
                    class="tw-flex tw-h-[700px] tw-items-center tw-justify-center"
                >
                    <v-progress-circular
                        size="large"
                        color="primary"
                        indeterminate
                    ></v-progress-circular>
                </div>
            </div>
        </template>
    </DialogModal>
</template>

<style scoped></style>
